import React from "react";
import PropTypes from "prop-types";
import "./index.sass";
import { LocalStorage } from "../../../shared";

const LanguageList = ({ className, onChange, languageOptions }) => {
	const currentLangue = LocalStorage.getItem("locale")
	return (
		<div className={`LanguageList ${className}`}>
			<div >
				{languageOptions.map((option) => (
					<button key={option.value} className={`${currentLangue === option.value ? "LanguageList__selected" : ''}`}
					value={option.value} onClick={() => onChange(option.value)} >
						{option.name}
					</button>
				))}
			</div>
		</div>
	);
};

LanguageList.propTypes = {
	className: PropTypes.string,
	languageOptions: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func
};

LanguageList.defaultProps = {
	className: "",
	onChange: () => {},
	languageOptions: [
		{ name: "PT", value: "pt" },
		{ name: "EN", value: "en" },
		// { name: "ES", value: "es" }
	]
};

export default LanguageList;
