import React from 'react';
import PropTypes from 'prop-types';
import {LocalStorage} from '../../../shared';
import './index.sass';

const Logo = ({className, name, type, onClick}) => {
	const currentValue = LocalStorage.getItem('locale') || "pt"
	if (name) {
		var url = `https://s3.amazonaws.com/roverpix-${type}s/${name.toLowerCase()}.png`
		if (type == "gallery-banner" || type == "home-banner"){
			url = `https://s3.amazonaws.com/roverpix-${type}s/${name.toLowerCase()}_${currentValue}.png`
		}
		return (
			<img
				onClick={onClick}
				className={`Logo ${className}`}
				src={url}
				alt=""
			/>
		);
	}

	return null;
};

Logo.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func
};

Logo.defaultProps = {
	className: '',
	name: '',
	type: 'logo',
	onClick: () => {}
};

export default Logo;
