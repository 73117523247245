import React from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import DeleteIcon from "@material-ui/icons/Delete";

import "./index.sass";

import Modal from "../../Atoms/Modal";
import Photo from "../../Atoms/Photo";
import Video from "../../Atoms/Video";
import Arrow from "../../Atoms/Arrow";
import Check from "../../Atoms/Check";
import PhotoDownload from "../../Atoms/PhotoDownload";
import IconButton from "../../Atoms/IconButton";
import Loader from "../../Atoms/Loader";
import Tooltip from "../../Atoms/ToolTip";

import { Order } from "../../../shared";

/* global document */

class MediaModal extends React.Component {
	constructor(props) {
		super(props);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.state = {
			originalMedia: null,
			error: null,
			loading: false,
		};
	}

	componentDidMount() {
		document.addEventListener("keydown", this.handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleKeyDown);
	}

	componentDidUpdate(prevProps) {
		const { isOpened, canDownloadMedia, media = {} } = this.props;
		const { id } = media || {};
		const { originalMedia, error, loading } = this.state;
		const { media: lastMedia = {} } = prevProps;
		const { id: lastId } = lastMedia || {};
		const notPhotoLoaded = !originalMedia && !loading && !error;
		const changedPhoto = !id || id !== lastId;

		if (
			isOpened &&
			(canDownloadMedia || media.canDownload) &&
			(notPhotoLoaded || changedPhoto)
		)
			this.getOriginalPhoto();
	}

	getOriginalPhoto = () => {
		const { media, isExtra } = this.props;
		this.setState({
			originalMedia: `${process.env.ROVERPIX_API}/api/v1/${isExtra ? "extra" : media.type
				}/download/${media.id}/${media.filename}`
		})
	};

	handleKeyDown(e) {
		const { onBefore } = this.props;
		const { onNext } = this.props;

		if (e.code === "ArrowLeft") {
			onBefore();
		}

		if (e.code === "ArrowRight") {
			onNext();
		}
	}

	render() {
		const {
			className,
			media,
			order,
			isOpened,
			canDownloadMedia,
			canRemoveMedia,
			isExtra,
			onClose,
			onBefore,
			onNext,
			intl,
			onAddToOrder,
			onDownloadMedia,
			onRemoveMedia,
			hasBeenVisible,
			handleTooltipClose,
			showToolTip
		} = this.props;
		const { loading, error, originalMedia } = this.state;
		const { formatMessage } = intl;
		const messages = defineMessages({
			download: {
				id: "media.modal.download",
				defaultMessage: "Fazer o download",
			},
			tooltipTitle: {
				id: "ToolTip.text",
				defaultMessage:
					"Para visualizar as mídias adquiridas sem marca d'agua, faça o download pelo botão esquerdo e/ou compartilhe pelo direito.",
			},
		});

		return (
			<Modal
				className={`MediaModal ${className}`}
				isOpened={isOpened}
				onClose={onClose}
			>
				<div className="MediaModal__container">
					<Arrow
						className="MediaModal__arrow MediaModal__arrow--right"
						size="small"
						onClick={onNext}
						direction="right"
						active
					/>
					<Arrow
						className="MediaModal__arrow MediaModal__arrow--left"
						size="small"
						direction="left"
						onClick={onBefore}
						active
					/>
					{media.id && media.filename && media.type === "photo" && (
						<Photo
							className="MediaModal__photo"
							id={media.id}
							filename={media.filename}
							source={media.preview_path}
							type="preview"
							isExtra={isExtra}
						/>
					)}
					{media.id && media.filename && media.type === "video" && (
						<Video
							className="MediaModal__video"
							id={media.id}
							filename={media.filename}
							source={media.preview_path}
							type="preview"
							isExtra={isExtra}
						/>
					)}
				</div>
				{canDownloadMedia || media.canDownload ? (
					<div className="MediaModal__actions">
						{loading && <Loader size="x-small" />}
						{!error && !loading && originalMedia && (
							<>
								<div className="MediaModal__actions__download">
									{ showToolTip &&
										<Tooltip className="MediaModal__actions__tooltip" onClick={handleTooltipClose} hasBeenVisible={hasBeenVisible} text={formatMessage(messages.tooltipTitle)}/>
									}
									<div>
										<PhotoDownload
											className="MediaModal__actions__download__button"
											href={originalMedia}
											filename={media.filename}
											onDownload={onDownloadMedia}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				) : (
					<Check
						className="MediaModal__check"
						active={Order.isMediaOnOrder(media.id, order)}
						onClick={(e) => onAddToOrder(e, media)}
					/>
				)}
				<div className="MediaModal__edit">
					{canRemoveMedia && (
						<IconButton rounded onClick={() => onRemoveMedia(media.id)}>
							<DeleteIcon fill="white" />
						</IconButton>
					)}
				</div>
			</Modal>
		);
	}
}

MediaModal.propTypes = {
	className: PropTypes.string,
	source: PropTypes.string,
	media: PropTypes.shape(),
	order: PropTypes.shape(),
	isOpened: PropTypes.bool,
	canDownloadMedia: PropTypes.bool,
	canRemoveMedia: PropTypes.bool,
	isExtra: PropTypes.bool,
	showToolTip: PropTypes.bool,
	allowSelect: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	onAddToOrder: PropTypes.func,
	onClose: PropTypes.func,
	onBefore: PropTypes.func,
	onNext: PropTypes.func,
	onDownloadMedia: PropTypes.func,
	onRemoveMedia: PropTypes.func,
};

MediaModal.defaultProps = {
	className: "",
	source: "",
	photo: {},
	order: {},
	isOpened: false,
	canDownloadMedia: false,
	canRemoveMedia: false,
	isExtra: false,
	showToolTip: true,
	allowSelect: true,
	onAddToOrder: () => {},
	onClose: () => {},
	onBefore: () => {},
	onNext: () => {},
	onDownloadMedia: () => {},
	onRemoveMedia: () => {},
};

export default injectIntl(MediaModal);
