import React, { useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Form from '../../Atoms/Form';
import Button from '../../Atoms/Button';
import Text from '../../Atoms/Text';
import TextField from '@material-ui/core/TextField';

import './index.sass';

const CredentialForm = ({ className, loading, onOpenCamera, onSubmit, intl }) => {
	const [credential, setCredential] = useState("")
	const {formatMessage} = intl;
	const messages = defineMessages({
		openCamera: {
			id: 'credentialForm.openCamera',
			defaultMessage: 'ABRIR CAMERA'
		},
		orUseCrededential: {
			id: 'credentialForm.orUseCredential',
			defaultMessage: 'Ou digite o número da sua credencial:'
		},
		searchButton: {
			id: 'credentialForm.searchButton',
			defaultMessage: 'BUSCAR'
		},
	});

	return (
		<div className={`CredentialForm ${className}`}>
			<Button className="CredentialForm__camera" onClick={onOpenCamera}>
				{formatMessage(messages.openCamera)}
			</Button>
			<Text className="CredentialForm__separator_text" variant="body2">
				{formatMessage(messages.orUseCrededential)}
			</Text>
			<Form
				className={`CredentialForm__form`}
				onSubmit={(e) => {
					e.preventDefault()
					onSubmit(credential)
				}}
			>
				<TextField id="external_id"
					className="CredentialForm__form__input"
					placeholder="EFHGAYWIH"
					onChange={(e) => setCredential(e.target.value)}
					name="credential"
					size="small"
					InputProps={{
						disableUnderline: true,
						classes: {
							input: 'MuiInput-input'
						},
					}}
				/>
				<Button className="CredentialForm__form__search" onClick={() => onSubmit(credential)}>
					{formatMessage(messages.searchButton)}
				</Button>
			</Form>
		</div>
	)
}

CredentialForm.propTypes = {
	className: PropTypes.string,
	intl: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	onSubmit: PropTypes.func,
	onOpenCamera: PropTypes.func
};

CredentialForm.defaultProps = {
	className: '',
	loading: false,
	onChange: () => { },
	onSubmit: () => { },
	onOpenCamera: () => { },
};

export default injectIntl(CredentialForm)
