import React from "react";
import { connect } from "react-redux";

import "./index.sass";

import Grid from "../../Molecules/Grid";
import AcceptPrivacy from "../../Molecules/AcceptPrivacy";
import Helmet from "../../Organisms/Helmet";
import GalleryHeader from "../../Organisms/GalleryHeader";
import PhotoGallery from "../../Organisms/PhotoGallery";
import Cart from "../../Organisms/Cart";
import HomeHeader from '../../Organisms/HomeHeader';
import { fetchCard } from "../../../store/actions";

class Gallery extends React.Component {
	componentDidMount() {
		const {
			card: cardState,
			fetchCard,
			organization: organizationState,
		} = this.props;
		const { card } = cardState;
		const { organization = {} } = organizationState || {};
		const { id } = organization || {};

		if (Object.keys(card).length === 0) {
			fetchCard(this.getCardCode(), id);
		}
	}

	getCardCode = () => {
		var path = window.location.pathname.split("/");
		var code = path[2];
		return code;
	};

	render() {
		const {
			organization: organizationState,
			card: cardState,
			products: productsState,
		} = this.props;
		const { organization } = organizationState;
		const { card } = cardState;
		const { all_digital, products } = productsState;
		const { has_online_sale, features } = organization;
		const { site }  = features
		var { show_home_header: showHomeHeader } = site;
		showHomeHeader = true
		const { can_sell_online } = card;
		const canBuy =
			(can_sell_online || has_online_sale) &&
			(all_digital !== undefined || products !== undefined);

		return (
			<div className="Gallery">
				<Helmet />
				<Grid type="container">
					{ showHomeHeader?
						<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
							<HomeHeader />
						</Grid>
						:
						<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
							<GalleryHeader canBuy={canBuy} cardCode={this.getCardCode()} />
						</Grid>
					}
				</Grid>
				<Grid className="Gallery__main" type="container">
					<Grid
						xs={12}
						sm={canBuy ? 9 : 12}
						md={canBuy ? 9 : 12}
						lg={canBuy ? 9 : 12}
						xl={canBuy ? 9 : 12}
					>
						<PhotoGallery cardCode={this.getCardCode()} canBuy={canBuy} />
					</Grid>
					{canBuy && (
						<>
							<Grid xs={12} sm={3} md={3} lg={3} xl={3}>
								<Cart cardCode={this.getCardCode()} />
							</Grid>
							<AcceptPrivacy />
						</>
					)}
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	organization: state.organization,
	card: state.card,
	products: state.products,
});

const mapActionsToProps = (dispatch) => ({
	fetchCard: (cardCode, organizationId) =>
		dispatch(fetchCard(cardCode, organizationId)),
});

export default connect(mapStateToProps, mapActionsToProps)(Gallery);
