import React from 'react';
import PropTypes from 'prop-types';
import {default as MaterialGrid} from '@material-ui/core/Grid';

import './index.sass';

const TYPES = ['container', 'item'];
const SPACINGS = [0, 8, 16, 24, 32, 40];
const SIZES = [false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const WRAPS = ['nowrap', 'wrap', 'wrap-reverse'];
const DIRECTIONS = ['row', 'row-reverse', 'column', 'column-reverse'];
const JUSTIFYS = ['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly'];
const ALIGNCONTENTS = ['stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around'];
const ALIGNITEMS = ['flex-start', 'center', 'flex-end', 'stretch', 'baseline'];


const Grid = ({
	className, type, wrap, direction, justify, alignContent, alignItems,
	spacing, xs, sm, md, lg, xl, children
}) => {
	if (type === 'container') {
		return (
			<MaterialGrid
				className={`Grid ${className}`}
				container
				wrap={wrap}
				direction={direction}
				justifyContent={justify}
				alignContent={alignContent}
				alignItems={alignItems}
				spacing={spacing}
			>
				{children}
			</MaterialGrid>
		);
	}

	return (
		<MaterialGrid
			className={`Grid ${className}`}
			item
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
		>
			{children}
		</MaterialGrid>
	);
};

Grid.propTypes = {
	className: PropTypes.string,
	type: PropTypes.oneOf(TYPES),
	wrap: PropTypes.oneOf(WRAPS),
	direction: PropTypes.oneOf(DIRECTIONS),
	justify: PropTypes.oneOf(JUSTIFYS),
	alignContent: PropTypes.oneOf(ALIGNCONTENTS),
	alignItems: PropTypes.oneOf(ALIGNITEMS),
	spacing: PropTypes.oneOf(SPACINGS),
	xs: PropTypes.oneOf(SIZES),
	sm: PropTypes.oneOf(SIZES),
	md: PropTypes.oneOf(SIZES),
	lg: PropTypes.oneOf(SIZES),
	xl: PropTypes.oneOf(SIZES),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

Grid.defaultProps = {
	className: '',
	type: 'item',
	wrap: 'wrap',
	direction: 'row',
	justify: 'flex-start',
	alignContent: 'stretch',
	alignItems: 'stretch',
	spacing: 0,
	xs: false,
	sm: false,
	md: false,
	lg: false,
	xl: false,
	children: null
};

export default Grid;
