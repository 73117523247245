import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {defineMessages, injectIntl} from 'react-intl';
import {LocalStorage, Analytics} from '../../../shared';

import Background from '../../Atoms/Background';
import Logo from '../../Atoms/Logo';
import Text from '../../Atoms/Text';
import Button from '../../Atoms/Button';
import Link from '../../Atoms/Link';
import Helmet from '../../Organisms/Helmet';
import SendPhotoForm from '../../Organisms/SendPhotoForm';
import Grid from '../../Molecules/Grid';
import LanguageChooser from '../../Molecules/LanguageChooser';
import CardForm from '../../Molecules/CardForm';
import CredentialForm from '../../Molecules/CredentialForm';
import GetCustomerForm from '../../Molecules/GetCustomerForm';
import ErrorModal from '../../Molecules/ErrorModal';
import FacialIcon from '../../../assets/images/face_recognition.png';
import FacialModal from '../../Organisms/FacialModal';

import {fetchCard, fetchCardCustomer,clearMedias, clearCard, fetchMedias} from '../../../store/actions';

import './index.sass';
import HomeHeader from '../../Organisms/HomeHeader';

/* global document */

class HomeTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidCard: false,
			cardCode: '',
			sendingPhoto: false,
			modalIsOpen: false,
			hasSale: false,
			secret: '',
			has2FA: false,
			facialModalIsOpen: false,
			credentialId: ""
		};

		this.parseCard = this.parseCard.bind(this);
		this.openFacialModal = this.openFacialModal.bind(this)
		this.readCredential = this.readCredential.bind(this)
	}

	componentDidMount() {
		this.getCardFromUrl()
	}

	componentDidUpdate(prevProps) {
		const { sendingPhoto } = prevProps;
		if (sendingPhoto) return;

		const { cardCode } = this.state;
		const { media: mediaState } = this.props;
		const { soldMedias = [], notSoldMedias = [], error, loading } = mediaState;
		const { history } = this.props;

		if (!error && !loading && ((soldMedias && soldMedias.length > 0) || (notSoldMedias && notSoldMedias.length > 0))) {
			if (this.state.credentialId !== ""){
				history.push('/gallery/' + cardCode + `?credential=${this.state.credentialId}`);
			} else {
				history.push('/gallery/' + cardCode);
			}
		}

		if (this.cardInput && this.cardInput.input) {
			this.cardInput.input.focus();
		}

		this.checkToShowModal()
	}

	checkToShowModal = () => {
		const {media: mediaState} = this.props;
		const {error} = mediaState;
		const {organization: orgState} = this.props;
		const {organization = {}} = orgState;
		const {has_online_sale} = organization;
		const { two_factor_authentication: twoFactorAuthentication } = this.props.organization.features_organization || {};

		if (error === 'CARD_EMPTY_MEDIA') {
			if (has_online_sale){
				this.openModal(true)
			} else {
				this.openModal(false)
			}
		}

		if (error === 'CUSTOMER_CODE_NOT_FOUND' || error === 'CARD_NOT_FROM_CUSTOMER')
			if (twoFactorAuthentication){
				this.openModal(true)
			} else {
				this.openModal(false)
			}
	}

	getCardFromUrl = () => {
		const {history} = this.props;
		const search = new URLSearchParams(history.location.search);
		const card = search.get('card');
		const { two_factor_authentication: twoFactorAuthentication } = this.props.organization.features_organization || {};

		if (card && card.length === 9) {
			this.setState({
				cardCode: `${card.substring(0, 2)}-${card.substring(2, 6)}-${card.substring(6, 9)}`
			}, () => {
				if (!twoFactorAuthentication && !sessionStorage.getItem('firstLoad')) {
					sessionStorage.setItem('firstLoad', true)
					this.parseCard()
				}
			});
		}
	}

	setInvalidCard(status = false) {
		this.setState({
			invalidCard: status
		});
	}

	parseCard(e = null) {
		if (e) {
			e.preventDefault();
		}

		let {cardCode} = this.state;
		cardCode = cardCode.replace(/[-,_]/g, '').toUpperCase();

		const {organization: orgState} = this.props;
		const {organization = {}} = orgState;
		const {features_organization = {}} = organization;
		const {two_factor_authentication:twoFatorAuthentication} = features_organization || {};

		if (twoFatorAuthentication) {
			let {secret} = this.state;
			secret = secret.toUpperCase();

			if (secret.length < 6) {
				this.setInvalidCard(true);
				return;
			} else {
				this.setInvalidCard(false);
			}
		}

		if (cardCode.length < 9) {
			this.setInvalidCard(true);
		} else {
			this.setInvalidCard(false);
			this.readCard([cardCode])
		}
	}

	parseCards = (cards) => (typeof cards === 'string' ? [cards] : cards);

	readCard = (cards) => {
		cards = this.parseCards(cards)
		const { fetchMedias, clearMedias, organization: organizationState} = this.props;
		const { organization = {} } = organizationState || {};
		const { id } = organization || {};
		const { secret } = this.state;
		clearMedias();
		fetchMedias(cards, id, secret);
		this.setState({
			cardCode: cards[0],
			secret: secret
		});
		this.sendCardReadEvent();
	}

	readCredential = async (credential) => {
		try {
			this.setState({"credentialId":credential})
			const url = `${process.env.ROVERPIX_API}/api/v1/card/customer/external_id/${credential}`;
			const response = await fetch(url, {method: "GET"}).then(res => res.json());
			console.log("sending analytics")
			Analytics.sendEvent('credential_read', 'browsing', credential);
			if (response.cards){
				this.readCard(response.cards)
			} else {
				this.closeFacialModal()
				this.openModal(false, false)
			}
		} catch(error) {
			console.error(error)
		}
	}

	sendCardReadEvent = () => {
		const {organization: orgState} = this.props;
		const {organization = {}} = orgState;
		const {name} = organization;

		Analytics.sendEvent('card_read', 'browsing', name);
	}

	searchCustomer = (customer) => {
		const {organization: orgState} = this.props;
		const {organization = {}} = orgState;
		const {id} = organization;
		const {fetchCardCustomer, clearMedias} = this.props;

		clearMedias();
		fetchCardCustomer(customer, id);
	}

	changeCardCode = (e) => {
		this.setState({
			cardCode: e.target.value,
			invalidCard: false
		});

		const {media: mediaState, clearMedias} = this.props;
		const {soldMedias, notSoldMedias, error} = mediaState;
		if ((soldMedias && notSoldMedias) || error) {
			clearMedias()
		}
	}

	changeSecret = (e) => {
		this.setState({
			secret: e.target.value
		});
	}

	openModal = (hasSale, has2FA) => {
		const {clearMedias} = this.props;
		this.setState({ modalIsOpen: true, hasSale, has2FA});
		clearMedias();
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	openFacialModal = () => {
		this.setState({ facialModalIsOpen: true });
	};

	closeFacialModal = () => {
		this.setState({ facialModalIsOpen: false });
	};

	render() {
		const {media: mediaState} = this.props;
		const {soldMedias = [], notSoldMedias = [], error, loading} = mediaState;
		const {organization: orgState} = this.props;
		const {organization = {}} = orgState;
		const {name, web_name, external_help_url, external_help_id, contact_email, features = {}, features_organization = {}, has_facial_recognition:hasFacialRecognition} = organization;
		const {site = {}} = features || {};
		var {can_send_photo: canSendPhoto, customer_form_fields: customerFormFields, cpf_as_password: cpfAsPassword, find_customer_with_credential: useCredential, show_home_header: showHomeHeader, free_photos: freePhotos} = site;
		showHomeHeader = true
		useCredential = true
		const {webapp_initial_form: webappInitialForm} = features_organization || {};
		const {cardCode, invalidCard, sendingPhoto, secret} = this.state;
		const {two_factor_authentication:twoFatorAuthentication} = features_organization || {};

		const {intl} = this.props;
		const {formatMessage} = intl;
		const emptyCard = soldMedias && soldMedias.length === 0 && notSoldMedias && notSoldMedias.length === 0;
		const messages = defineMessages({
			title: {
				id: 'home.title',
				defaultMessage: 'Veja aqui suas fotos mágicas!'
			},
			subtitle: {
				id: 'home.subtitle',
				defaultMessage: 'Digite o código do seu cartão-fotografia.'
			},
			subtitle_customer: {
				id: 'home.subtitle_customer',
				defaultMessage: 'Insira os dados conforme descrito abaixo.'
			},
			warning: {
				id: 'home.footer.warning',
				defaultMessage: 'Suas fotos podem demorar até 48 horas para ficarem disponíveis.'
			},
			contact: {
				id: 'home.footer.contact',
				defaultMessage: 'Em caso de dúvidas, '
			},
			redirectContactEmail: {
				id: 'home.footer.contact.email',
				defaultMessage: 'entre em contato através do email:'
			},
			redirectContact: {
				id: 'home.footer.contact.link',
				defaultMessage: 'clique aqui'
			},
			button: {
				id: 'home.button',
				defaultMessage: 'Carregar cartão'
			},
			customerButton: {
				id: 'home.customerButton',
				defaultMessage: 'Pesquisar cliente'
			},
			invalidCard: {
				id: 'home.card.invalid',
				defaultMessage: 'Cartão inválido'
			},
			cardNotFound: {
				id: 'home.card.notFound',
				defaultMessage: 'Cartão inválido'
			},
			cardEmptyPhoto: {
				id: 'home.card.emptyPhoto',
				defaultMessage: 'Não há fotos disponíveis para este cartão'
			},
			cardGenericError: {
				id: 'home.card.genericError',
				defaultMessage: 'Algo de errado aconteceu'
			},
			customerNotFound: {
				id: 'home.customer.notFound',
				defaultMessage: 'Cliente não encontrado'
			},
			sendPhotos: {
				id: 'home.sendPhotos',
				defaultMessage: 'Enviar fotos'
			},
			facialRecognition: {
				id: 'home.facialRecognition',
				defaultMessage: 'Enviar fotos'
			},
			credentialFormText: {
				id:'home.credentialFormText',
				defaultMessage: 'Clique no botão para ativar sua câmera e escanear o QR Code da sua credencial'
			}
		});

		const getErrorMessage = (error) => {
			if (invalidCard) {
				return formatMessage(messages.invalidCard);
			}

			switch (error) {
			case 'CARD_NOT_FOUND':
				return formatMessage(messages.cardNotFound);
			case 'CARD_EMPTY_MEDIA':
				return formatMessage(messages.cardEmptyPhoto);
			case 'CUSTOMER_NOT_FOUND':
				return formatMessage(messages.customerNotFound);
			default:
				return formatMessage(messages.cardGenericError);
			}
		};

		const getButtonMessage = () => {
			if (invalidCard || error || emptyCard) {
				return getErrorMessage(error);
			}
			return formatMessage(messages.button);
		};

		const getButtonCustomerMessage = () => {
			if (error) {
				return getErrorMessage(error);
			}

			if (emptyCard) {
				return formatMessage(messages.cardEmptyPhoto);
			}

			return formatMessage(messages.customerButton);
		};
		const handleChangeLanguage = (value) => {
			const currentValue = LocalStorage.getItem('locale');

			if (currentValue !== value) {
				Analytics.sendEvent(`change_language_to_${value}`, 'browsing', name);
				LocalStorage.setItem('locale', value);
				document.location.reload(true);
			}
			if (this.cardInput && this.cardInput.input) {
				this.cardInput.input.focus();
			}
		};

		return (
			<div className="Home">
				<Helmet />
				<Background name={web_name} />
				{ showHomeHeader &&
					<Grid type="container">
						<Grid xs={12} sm={12} md={12} lg={12} xl={12}>
							<HomeHeader />
						</Grid>
					</Grid>
				}
				<div className="Home__content">
					<Logo className="Home__banner" type="home-banner" name={web_name} />
					{!freePhotos &&
						<Text className="Home__title" variant="h2" paragraph>
							{formatMessage(messages.title)}
						</Text>
					}
					{canSendPhoto && sendingPhoto ? (
						<SendPhotoForm
							onClose={(cardToShow) => {
								this.setState({
									sendingPhoto: false
								});

								if (cardToShow) this.readCard(cardToShow);
							}}
						/>
					) : (
						<div className="Home__form">
							{webappInitialForm && webappInitialForm === 'customer' ? (
								<>
									<Text className="Home__subtitle" variant="subtitle1" paragraph>
										{formatMessage(messages.subtitle_customer)}
									</Text>
									<GetCustomerForm
										fields={customerFormFields}
										cpfAsPassword={cpfAsPassword}
										loading={loading}
										buttonMessage={getButtonCustomerMessage()}
										hasError={Boolean(invalidCard || error || emptyCard)}
										onSubmit={this.searchCustomer}
									/>
								</>
							) : (
								<>
									<Text className="Home__subtitle" variant="body2">
										{useCredential ? formatMessage(messages.credentialFormText):formatMessage(messages.subtitle_customer)}
									</Text>
									{ useCredential ?
										<CredentialForm
											loading={loading}
											onOpenCamera={this.openFacialModal}
											onSubmit={this.readCredential}/>											:

										<CardForm
											cardCode={cardCode}
											secret={secret}
											loading={loading}
											buttonMessage={getButtonMessage()}
											hasError={Boolean(invalidCard || error)}
											onChange={this.changeCardCode}
											onChangeSecret={this.changeSecret}
											onSubmit={this.parseCard}
											show2FA={twoFatorAuthentication}
										>
										{canSendPhoto && (
											<Button
												className="Home__button"
												color='quaternary'
												onClick={() => {
													this.setState({
														sendingPhoto: true
													})
												}}
											>
												{formatMessage(messages.sendPhotos)}
											</Button>
										)}
										</CardForm>
									}
									{hasFacialRecognition && (
										<div className="Home__facialRecognition">
											<Text className="Home__facialRecognition__subtitle" variant="subtitle2">
												{formatMessage(messages.facialRecognition)}
											</Text>
											<Button
												className="Home__facialRecognition__button"
												color="secondary"
												size="medium"
												onClick={this.openFacialModal}
											>
												<img src={FacialIcon} alt="Facial Recognition" className="Home__facialRecognition__button__icon" />
											</Button>
										</div>
									)}
								</>
							)}
						</div>
					)}
					{ !freePhotos &&
						<Text className="Home__footer Home__footer--main" variant="caption">
							{formatMessage(messages.warning)}
						</Text>
					}
					<div className="Home__footer" >
						<Text className="Home__footer__text" variant="body2">
							{`${formatMessage(messages.contact)} `}
							{
								external_help_url ? (
									<Link href={`${external_help_url}?qrcode=${cardCode}&empresa=${external_help_id}`} >
										{formatMessage(messages.redirectContact)}
									</Link>
								) : (
									<>
										{formatMessage(messages.redirectContactEmail)}
										<Link
											href={contact_email || 'contato@roverpix.com'}
											subject='Ajuda para ver minhas fotos digitais'
											body={`Código do cartão: ${cardCode}`}
										/>
									</>
								)
							}
						</Text>
						{
							!showHomeHeader &&
							<LanguageChooser
								onChange={handleChangeLanguage}
							/>
						}
					</div>
				</div>
				<ErrorModal open={this.state.modalIsOpen} onClose={this.closeModal} hasSale={this.state.hasSale} showError2FA={this.state.has2FA} hasCredential={useCredential} />
				<FacialModal
					credentialMode={useCredential}
					onReadCredential={this.readCredential}
					onCardsSelected={this.readCard}
					open={this.state.facialModalIsOpen}
					onClose={this.closeFacialModal}
					organizationId={organization.id} />
			</div>
		);
	}
}

HomeTemplate.propTypes = {
	intl: PropTypes.object.isRequired,
	card: PropTypes.shape({
		card: PropTypes.shape({
			id: PropTypes.string
		}),
		photo_count: PropTypes.number
	}),
	organization: PropTypes.shape({
		organization: PropTypes.shape({
			name: PropTypes.string,
			web_name: PropTypes.string,
			url: PropTypes.string,
			contact_email: PropTypes.string,
			two_factor_authentication: PropTypes.bool
		}),
		loading: PropTypes.bool,
		error: PropTypes.string,
		secret: PropTypes.string,
	}).isRequired,
	history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	card: state.card,
	media: state.media,
	organization: state.organization,
	secret: state.secret,
});

const mapActionsToProps = dispatch => ({
	fetchMedias: (cardCode,organizationId,secret) => dispatch(fetchMedias(cardCode, organizationId, secret)),
	fetchCard: (cardCode,organizationId) => dispatch(fetchCard(cardCode, organizationId)),
	fetchCardCustomer: (customer, organization_id) => dispatch(fetchCardCustomer(customer, organization_id)),
	clearMedias: () => dispatch(clearMedias()),
	clearCard: () => dispatch(clearCard()),
});

export default withRouter(connect(mapStateToProps, mapActionsToProps)(injectIntl(HomeTemplate)));
