import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from 'prop-types';
import { Html5Qrcode } from "html5-qrcode";
import "./index.sass";

const QRCodeReaderCamera = ({ className, onResult }) => {
	const html5QrCodeRef = useRef(null);

	const handleResult = useCallback(
		async (decodedText) => {
			onResult(decodedText);
			await stopScanner();
		},
		[onResult]
	);

	const startScanner = async () => {
		await stopScanner();
		try {
			html5QrCodeRef.current = new Html5Qrcode("QRCodeReaderCameraContainer");
			await html5QrCodeRef.current.start(
				{ facingMode: "environment" },
				{
					fps: 30,
					qrbox: { width: 150, height: 150 },
					videoConstraints: {
						width: { ideal: 1920 },
						height: { ideal: 1080 },
						facingMode: "environment"
					}
				},
				handleResult,
				(error) => console.log("QR Code scan error:", error)
			);
		} catch (error) {
			console.error("QR Code start error:", error);
		}
	};

	const stopScanner = async () => {
		if (html5QrCodeRef.current) {
			try {
				await html5QrCodeRef.current.stop();
				html5QrCodeRef.current.clear();
				html5QrCodeRef.current = null;
			} catch (error) {
				console.warn("Error stopping the scanner:", error);
			}
		}
	};

	useEffect(() => {
		startScanner();

		return () => {
			stopScanner();
		};
	}, []);

	return (
		<div className={`QRCodeReaderCamera ${className}`}>
			<div className="QRCodeReaderCamera__Container">
				<div className="QRCodeReaderCamera__Container__Wrapper">
					<div id="QRCodeReaderCameraContainer" className="QRCodeReaderCamera__Container__Wrapper__Webcam"></div>
				</div>
			</div>
		</div>
	);
};

QRCodeReaderCamera.propTypes = {
	className: PropTypes.string,
	onResult: PropTypes.func.isRequired
};

QRCodeReaderCamera.defaultProps = {
	className: ''
};

export default QRCodeReaderCamera;
