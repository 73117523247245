import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';
import {defineMessages, injectIntl} from 'react-intl';

const PhotoDownload = ({ className, intl, href, filename, onDownload }) => {
	const {formatMessage} = intl;
	const messages = defineMessages({
		buttonText: {
			id: 'photoDowload.buttonText',
			defaultMessage: 'BAIXAR A FOTO'
		}
	})
	return (
		<div className={`PhotoDownload ${className}`}>
			<a
				className={`PhotoDownload__Link ${className}`}
				href={href}
				onClick={onDownload}
				download={filename}
			>
				{formatMessage(messages.buttonText)}
			</a>
		</div>
	);
}

PhotoDownload.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string,
	filename: PropTypes.string,
	onDownload: PropTypes.func
};

PhotoDownload.defaultProps = {
	className: '',
	href: '',
	filename: '',
	onDownload: () => {}
};

export default injectIntl(PhotoDownload);
