import React from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import Text from '../../Atoms/Text';
import IconButton from '../../Atoms/IconButton';

import CloseIcon from "@material-ui/icons/CloseRounded";

import "./index.scss";

const ErrorModal = ({ className, open, intl, onClose, hasSale, showError2FA, hasCredential}) => {
	if (!open) return null;
	const { formatMessage } = intl;

	const messages = defineMessages({
		title: {
			id: "errorModal.title",
			defaultMessage: "Não há mídias disponíveis no cartão!",
		},
		title_2FA: {
			id: "errorModal.title2FA",
			defaultMessage: "Código de acesso inválido!",
		},
		has_online_sale: {
			id: "errorModal.message.sale",
			defaultMessage:
				"Por favor, aguarde alguns instantes até que suas mídias estejam disponíveis para compra. Caso já tenha se passado um tempo considerável, por favor, entre em contato com o suporte para obter ajuda.",
		},
		no_online_sale: {
			id: "errorModal.message.noSale",
			defaultMessage:
				"Lamentamos, mas não fazemos vendas online. As fotos estão disponíveis apenas no estabelecimento.",
		},
		wrong_customer_code: {
			id: "errorModal.message.wrongCustomerCode",
			defaultMessage: "Por favor, verifique o código e tente novamente.",
		},
		close_button: {
			id: "errorModal.button",
			defaultMessage: "Entendi",
		},
		noCredentialFound: {
			id: "errorModal.noCredentialFound",
			defaultMessage: "Não encontramos nenhuma foto na sua credential.",
		},
		tryAgainLater: {
			id: "errorModal.tryAgainLater",
			defaultMessage: "Tente novamente mais tarde."
		}
	});

	var getTitle = () => {
		if (hasCredential){
			return messages.noCredentialFound
		}
		if (showError2FA) {
			return messages.title_2FA
		}

		return messages.title
	}

	var getMessage = () => {
		if (hasCredential){
			return messages.tryAgainLater
		}
		else if (showError2FA) {
			return messages.wrong_customer_code
		} else if (hasSale) {
			return messages.has_online_sale
		}

		return messages.no_online_sale
	}

	return (
		<section className="ErrorModal">
			<div className="ErrorModal__Container">
				<div className="ErrorModal__Content">
					<IconButton className="ErrorModal__CloseButtonX" onClick={onClose}>
						<CloseIcon  />
					</IconButton>
					{hasCredential &&
						<img
							className={`ErrorModal__img`}
							src={`https://s3.amazonaws.com/roverpix-error-banners/ccxp.png`}
							alt=""
						/>
					}
					<div className="ErrorModal__TextSection">
						<Text className="ErrorModal__TextSection__Title" variant="subtitle1">
							{formatMessage(getTitle())}
						</Text>
						<Text className="ErrorModal__TextSection__Subtitle" variant="caption">
							{formatMessage(getMessage())}
						</Text>
					</div>
					{!hasCredential &&
						<button className="ErrorModal__CloseButton" onClick={onClose}>
							{formatMessage(messages.close_button)}
						</button>
					}
				</div>
			</div>
		</section>
	);
};

ErrorModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	onClose: PropTypes.func,
	hasCredential: PropTypes.bool
};

ErrorModal.defaultProps = {
	className: "",
	cardCode: "",
	open: false,
	hasCredential: false,
	onClose: () => {},
};

export default injectIntl(ErrorModal);
