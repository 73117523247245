import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import "./index.sass";
import { Analytics, LocalStorage } from "../../../shared";
import Box from "../../Molecules/Box";
import Grid from "../../Molecules/Grid";
import LanguageList from "../../Molecules/LanguageList"
import Logo from "../../Atoms/Logo";

import {
	clearMedias,
	clearOrder,
	clearCard
} from "../../../store/actions";

class HomeHeader extends React.Component {
	constructor(props) {
		super(props);
		this.onBack = this.onBack.bind(this);
		this.hasCheckedMediasCard = false;
	}

	componentDidUpdate() {
		this.checkOrderMediasCard();
	}
	componentWillUnmount() {
		this.cleanCache()
	}
	cleanCache = () => {
		const { clearMedias, clearOrder, clearCard } = this.props;
		clearMedias();
		clearOrder();
		clearCard();
	}
	onBack() {
		const { organization } = this.props;
		const { name: orgName } = organization;
		this.cleanCache()
		this.props.history.push('/');
		Analytics.sendEvent("leave_gallery", "browsing", orgName);
	}
	checkOrderMediasCard() {
		const { order: orderState, media } = this.props;
		const { order } = orderState;
		if (!this.hasCheckedMediasCard && order.cards) {
			let { soldMedias, notSoldMedias } = media;
			const medias = [...(soldMedias || []), ...(notSoldMedias || [])];
			const cards = [...new Set(medias.map((media) => media.card))];
			cards.map((card) => this.checkAddCardMediaToOrder(order, card));
			this.hasCheckedMediasCard = true;
		}
	}
	render() {
		const { organization } = this.props;
		const {  web_name } = organization.organization;

		const handleChangeLanguage = (value) => {
			const currentValue = LocalStorage.getItem("locale");
			if (currentValue !== value) {
				Analytics.sendEvent(`change_language_to_${value}`, "browsing");
				LocalStorage.setItem("locale", value);
				document.location.reload(true);
			}
		};
		return (
			<div className="HomeHeader">
				<Box className="HomeHeader__wrapper" borderPosition="none" >
					<Grid className="HomeHeader__container"  sm={6} md={6} lg={6} xl={6}>
					</Grid>
					<Grid className="HomeHeader__container"  xs={6} sm={6} md={6} lg={6} xl={6}>
						<Logo className="HomeHeader__logo" name={web_name} onClick={this.onBack}/>
					</Grid>
					<Grid className="HomeHeader__container" xs={6} sm={6} md={6} lg={6} xl={6}>
						<LanguageList className="HomeHeader__languages" onChange={handleChangeLanguage}/>
					</Grid>
				</Box>
			</div>
		);
	}
}

HomeHeader.propTypes = {
	organization: PropTypes.shape({
		name: PropTypes.string,
		web_name: PropTypes.string,
		url: PropTypes.string,
		contact_email: PropTypes.string,
	}).isRequired,
	intl: PropTypes.object.isRequired,
};

HomeHeader.defaultProps = {
};

const mapStateToProps = (state) => ({
	organization: state.organization,
	order: state.order,
	media: state.media
});

const mapActionsToProps = (dispatch) => ({
	clearMedias: () => dispatch(clearMedias()),
	clearOrder: () => dispatch(clearOrder()),
	clearCard: () => dispatch(clearCard())
});

export default withRouter(
	connect(mapStateToProps, mapActionsToProps)(injectIntl(HomeHeader))
);
