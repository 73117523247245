import React from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";

import Button from "../../../../Atoms/Button";
import Text from "../../../../Atoms/Text";
import SwitchButton from "../../../../Atoms/SwitchButton";

import "./index.sass";
import { yellow } from "@material-ui/core/colors";

const FacialModalSettings = ({ className, intl, returnButton, onMirrorSwitch, checkMirrorSwitch, onChangeCamera, checkChangeCamera }) => {
	const { formatMessage } = intl;
	const messages = defineMessages({
		returnButton: {
			id: 'facialModal.returnButton',
			defaultMessage: "Voltar",
		},
		mirrorCamera: {
			id: 'facialModal.mirrorCamera',
			defaultMessage: "Espelhar câmera",
		},
		changeCamera: {
			id: 'facialModal.changeCamera',
			defaultMessage: "Trocar câmera",
		},
	});

	return (
		<div className={`FacialModalSettings ${className}`}>
			{/* <section className="FacialModalSettings__MirrorCamera">
				<Text variant="subtitle2">
					{formatMessage(messages.mirrorCamera)}
				</Text>
				<SwitchButton color={yellow}  className={`FacialModalSettings__switch`} onChange={onMirrorSwitch} checked={checkMirrorSwitch}/>
			</section> */}
			<section className="FacialModalSettings__ChangeCamera">
				<Text variant="subtitle2">
					{formatMessage(messages.changeCamera)}
				</Text>
				<SwitchButton color={yellow} className={`FacialModalSettings__switch`} onChange={onChangeCamera} checked={checkChangeCamera}/>
			</section>
			<Button className="FacialModalSettings__ReturnButton" onClick={returnButton}>
				{formatMessage(messages.returnButton)}
			</Button>
		</div>
	);
};

FacialModalSettings.propTypes = {
	className: PropTypes.string,
	returnButton: PropTypes.func,
	onMirrorSwitch: PropTypes.func.isRequired,
	checkMirrorSwitch: PropTypes.bool.isRequired,
	onChangeCamera: PropTypes.func.isRequired,
	checkChangeCamera: PropTypes.bool.isRequired,
};

FacialModalSettings.defaultProps = {
	className: "",
	returnButton: () => {},
};

export default injectIntl(FacialModalSettings);
