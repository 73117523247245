import React from "react";
import PropTypes from "prop-types";
import QRCodeReaderCamera from "../../../../Atoms/QRCodeReaderCamera";
import Text from "../../../../Atoms/Text";
import { defineMessages, injectIntl } from "react-intl";

import "./index.sass";

const QRCodeModalCamera = ({ intl, className, onResult }) => {
	const {formatMessage} = intl
	const messages = defineMessages({
		instructions: {
			id: 'modalCamera.instructions',
			defaultMessage: 'Posicione o qrcode de sua credencial em frente a câmera para acessar suas fotos.'
		}
	})
	return (
		<div className={`QRCodeModalCamera ${className}`} >
			<div className={`QRCodeModalCamera__title_wrapper`}>
				<Text className={`QRCodeModalCamera__title`}>
					{formatMessage(messages.instructions)}
				</Text>
			</div>
			<div className="QRCodeModalCamera__camera_wrapper">
				<QRCodeReaderCamera
					className="QRCodeModalCamera__camera"
					onResult={onResult}
				/>
			</div>
		</div>
	);
};

QRCodeModalCamera.propTypes = {
	intl: PropTypes.object.isRequired,
	className: PropTypes.string,
	onResult: PropTypes.func,
};

QRCodeModalCamera.defaultProps = {
	intl: PropTypes.object.isRequired,
	className: "",
	onResult: () => {},
};

export default injectIntl(QRCodeModalCamera);
